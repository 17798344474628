<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/attendance`" title="Attendance" :columns="columns"
                routerpath="/hr/addattendance" addTourPath="/hr/addtour" :formOptions="formOptions" :edit="edit"
                :add="add" :canDelete="canDelete" :viewFields="viewFields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/components/axios";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    flatPickr,
    GoodTableColumnSearch,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      viewFields: [
        {
          label: "Employee",
          field: "employee",
          type: "dropdown",
          responseValue: "name",
          class: "col-md-4",
        },
        {
          label: "Date",
          field: "date",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Reason",
          field: "reason",
          type: "text",
          class: "col-md-4",
        },
      ],
      columns: [
        {
          label: "ID",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search ID",
          },
          hidden: true,
        },
        {
          label: "Employee",
          field: "employee",
          type: "dropdown",
          url: "team",
          responseValue: "name",
          dropdownname: "name",

          filterOptions: {
            enabled: true,
            placeholder: "Search Employee Name",
          },
        },
        {
          label: "Date",
          field: "date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Start Date",
          field: "startdate",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date Time",
          },
        },
        {
          label: "End Date",
          field: "enddate",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date Time",
          },
        },
        {
          label: "Reason",
          field: "reason",
          filterOptions: {
            enabled: true,
            placeholder: "Search Reason",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: "fullname",
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: "fullname",
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Attendance" : "Add Attendance"}`,
        submitRouterPath: "/hr/attendance",
        usersDropdown: true,
        branchDropdown: true,
        designationDropdown: true,
        method: "post",
        action: "add",
        gridForm: true,
        getEditValue: `${baseApi}/getattendanceById`,
        url: `${baseApi}/attendance`,
        inputFields: [
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          // {
          //   name: "nextfollowupdate",
          //   value: `${moment(new Date()).format("DD/MM/yyyy")}`,
          //   type: "hidden",
          // },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Employee",
            name: "employee",
            value: "",
            type: "dropdown",
            class: "col-md-12",
            dropdownname: "employee",
            url: "team",
            responseValue: "id",
            required: true,
            options: [],
          },
          {
            label: "Date",
            name: "date",
            value: moment(new Date()).format("DD/MM/YYYY"),
            type: "date",
            placeholder: "Enter Date",
            class: "col-md-12",
            required: true,
          },
          {
            label: "Reason",
            name: "reason",
            value: "",
            type: "text",
            placeholder: "Enter Reason",
            class: "col-md-12",
            required: true,
          },
        ],
      },
      edit: "",
      add: "",
      canDelete: "",
    };
  },
  beforeMount() {
    this.getDropdowns();
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Attendance") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push("/");
          }
        }
      });
    }
  },
  methods: {
    async getDropdowns() {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      this.formOptions.inputFields.map(async (item) => {
        if (item.type == "dropdown" && item.url) {
          const user = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            url: `${baseApi}/${item.url}`,
          };

          await axios(user)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data);

              // this.form[item.name] = data[item.name];
              // if (item.name == item.dropdownname) {
              //   item.options.map((user) => {
              //     if (user.id == this.form[item.dropdownname]) {
              //       item.item = user.name;
              //     }
              //   });
              // }
            })
            .catch((error) => console.log(error, "error"));
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
